import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useImageLoader from "../../../omnia/hooks/use-image-loader";
import { Parallax, Background } from "react-parallax";

function PictureContent({src}){
    const { imageSrc } = useImageLoader(src, '/assets/images/loading_cms_dark.jpg');
    return <img src={imageSrc} alt="Website Picture" />;
}

function Picture({component, texts, setup, images, sendMessage, t}){

    const [data, setData] = useState(null);

    useEffect(() => {
        if(typeof setup['images'] !== "undefined" && typeof setup['misc'] !== "undefined"){
            if(images.filter(i => i.id === setup['images']['main']).length > 0){
                setData({
                    image: images.find(i => i.id === setup['images']['main']).download,
                    setup: setup['misc']
                })
            } else {
                setData({
                    image: '/assets/images/loading_image.jpg',
                    setup: setup['misc']
                })
            }
        }
    }, [setup, images]);

    if(!data)
        return null;

    return (
        <>
            {data['setup']['fullwidth'] ? (
                <Parallax strength={parseInt(data['setup']['speed']) * 100} style={{height: data['setup']['height'] + 'vh', width: '100%'}}>
                    <Background>
                        <PictureContent style={{height: data['setup']['height'] + 'vh', width: '100%'}} src={data['image']} />
                    </Background>
                </Parallax>
            ) : (
                <div style={{margin: 20}}>
                    <Parallax strength={parseInt(data['setup']['speed']) * 100} style={{height: data['setup']['height'] + 'vh', borderRadius: 5}}>
                        <Background>
                            <PictureContent style={{height: data['setup']['height'] + 'vh'}} src={data['image']} />
                        </Background>
                    </Parallax>
                </div>
            )}
        </>
    );
}

Picture.propTypes = {
    component: PropTypes.object,
    images: PropTypes.array,
    texts: PropTypes.array,
    setup: PropTypes.object,
    sendMessage: PropTypes.func
}

export default Picture;